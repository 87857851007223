.validation-summary {
  ul {
    list-style: none;
    margin: 0;
  }
}

.logo {
  width: 320px;
  height: 35px;
}

.social-login-section {
  border-top: 1px solid #eee;
  padding: 12px 0 0;
}

.social-login-section-or {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.panel-default > .panel-heading {
  background-color: white;
}
