.license-warning {
  .warning-message {
    display: flex;
    align-items: center;
    height: 35px;
    padding-left: 8px;
  
    i {
      color: #C81E29 !important;
      font-size: 16px !important;
      margin-right: 5px;
    }
  
    span {
      color: #C81E29 !important;
      font-size: 12px !important;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }
}